import React from 'react';

import LargeItem from './Items/LargeItem';
import MediumItem from './Items/MediumItem';
import SmallItem from './Items/SmallItem';

const base = 'terminal-main-items';

export default function MainItems({
    items,
    comboItem,
    comboPrice,
    sections,
    onAddToCart,
    configureCombo,
    handleShowSplash
}) {
    const largeItem = items[0];
    const mediumItems = items.slice(1, 4);
    const smallItemsScreen1 = items.slice(4, 10);
    const smallItemsGrid = items.slice(10, 40);
    const itemXMargin = 7;
    const itemYMargin = 7;
    const widthOfItems = 238;
    let imageList = [
        "https://image.popcontent.link/content-card/ranks/rank1.png",
        "https://image.popcontent.link/content-card/ranks/rank2.png",
        "https://image.popcontent.link/content-card/ranks/rank-3.png",
        "https://image.popcontent.link/content-card/ranks/rank-4.png",
        "https://image.popcontent.link/content-card/ranks/rank-5.png"
    ]

    const scrollToSection = (sectionId) => {
        if (!sectionId) {
            return;
        }
        const section = document.getElementById(sectionId);
        const offset = 60; // 60px above the section
        const sectionPosition = section.getBoundingClientRect().top;
        const scrollPosition = document.documentElement.scrollTop;
        window.scrollTo({
          top: sectionPosition + scrollPosition - offset,
          behavior: 'smooth'
        });
      }

    const fetchItemAndAddToCart = (itemId) => {
        const item = items.filter((item) => item.menu_item_id == itemId)[0];
        onAddToCart(item, false);
    }

    return (
        <>
            {comboItem ? (
                <div className={`${base}`}>
                    <div className={`${base}__menu_screen_1`}>
                        <div className={`${base}__screen1_rhs`}>
                            <LargeItem
                                showBestSeller={true}
                                item={largeItem}
                                comboPrice={comboPrice}
                                width={widthOfItems}
                                comboItem={comboItem}
                                height={582}
                                borderRadius={'3px'}
                                itemWithBlackBorder={false}
                                itemXMargin={itemXMargin}
                                itemYMargin={itemYMargin}
                                onAddToCart={onAddToCart}
                            />
                        </div>
                        <div className={`${base}__screen1_lhs`}>
                            <div className="medium-items">
                                {mediumItems.map((item) => (
                                    <MediumItem
                                        key={item.menu_item_id}
                                        item={item}
                                        comboPrice={comboPrice}
                                        width={widthOfItems}
                                        comboItem={comboItem}
                                        height={280}
                                        borderRadius={'3px'}
                                        itemWithBlackBorder={false}
                                        itemXMargin={itemXMargin}
                                        itemYMargin={itemYMargin}
                                        onAddToCart={onAddToCart}
                                    />
                                ))}
                            </div>
                            <div className="small-items">
                                {smallItemsScreen1.map((item) => (
                                    <SmallItem
                                        key={item.menu_item_id}
                                        item={item}
                                        comboPrice={comboPrice}
                                        width={widthOfItems}
                                        height={138}
                                        comboItem={comboItem}
                                        borderRadius={'3px'}
                                        itemWithBlackBorder={false}
                                        itemXMargin={itemXMargin}
                                        itemYMargin={itemYMargin}
                                        onAddToCart={onAddToCart}
                                    />
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className={`${base}__small_items_grid`}>
                        {smallItemsGrid.map((item) => (
                            <SmallItem
                                key={item.menu_item_id}
                                item={item}
                                comboPrice={comboPrice}
                                width={widthOfItems}
                                height={138}
                                comboItem={comboItem}
                                borderRadius={'3px'}
                                itemWithBlackBorder={false}
                                itemXMargin={itemXMargin}
                                itemYMargin={itemYMargin}
                                onAddToCart={onAddToCart}
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <div className={`${base}`}>
                    <div style={{marginBottom : "20rem"}}>
                        {sections.map(section => (
                            section.type == "Masthead" ? (
                                <div className={`${base}__masthead display--flex`} id={section.id} key={section.id}>
                                    <div style={{maxWidth: '50%'}}>
                                        <p className="heading">
                                            {section.text}
                                        </p>
                                        <p className="sub_heading">
                                            {section.sub_text}
                                        </p>
                                        {section.cta_text && (
                                            section.button_type == "Open_Link" ? (
                                                <></>
                                            ) : section.button_type == "Lead_To_Menu_Item" ? (
                                                <button className="button" onClick={() => fetchItemAndAddToCart(section.target)}>
                                                        {section.cta_text}
                                                </button>
                                            ) : (
                                                <button className="button" onClick={() => scrollToSection(section.target)}>
                                                        {section.cta_text}
                                                </button>
                                            )
                                        )}
                                    </div>
                                    {section.button_type == "Open_Link" ? (
                                        <div className="masthead_image" style={{backgroundImage : `url(${section.image})`}}/>
                                    ) : section.button_type == "Lead_To_Menu_Item" ? (
                                        <div className="masthead_image" style={{backgroundImage : `url(${section.image})`}} onClick={() => fetchItemAndAddToCart(section.target)}/>
                                    ) : (
                                        <div className="masthead_image" style={{backgroundImage : `url(${section.image})`}} onClick={() => scrollToSection(section.target)}/>
                                    )
                                    }
                                </div>
                            ) : (
                                (section.top_item_category && section.items && section.items.length > 0) ? (
                                    <div className={`${base}__top_item_category`} id={section.id} key={section.id}>
                                        <div className={`${base}__item_card_heading`}>
                                            {section.text}
                                        </div>
                                        <div className={`${base}__item_cards`}>
                                            {section.items.slice(0, 5).map((item, index) => (
                                                <div className="item_card display--flex" style={{marginLeft: "1rem"}}  key={item.menu_item_id}>
                                                    <img className="ranking_image" src={imageList[index]} />
                                                    <div style={{position: 'relative'}}
                                                        onClick={() => onAddToCart(item, false)}
                                                    >
                                                        <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                                        <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                                        <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
                                                            <p style={item.special_offer_price ? {textDecoration: 'line-through'} : {}}>{item.price ? item.price.toFixed(2) : item.price}</p>
                                                            <p style={{marginLeft: '8px', fontWeight: '700'}}>{item.special_offer_price ? item.special_offer_price.toFixed(2) : item.special_offer_price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    (section.card_size == "Large" && section.items && section.items.length > 0) ? (
                                        <div className={`${base}__large_menu_line`} id={section.id} key={section.id}>
                                            <div className={`${base}__item_card_heading`}>
                                                {section.text}
                                            </div>
                                            <div className={`${base}__item_cards`}>
                                                {section.items.map((item) => (
                                                    <div className="display--flex" style={{marginLeft: "1rem"}} key={item.menu_item_id}>
                                                        <div style={{position: 'relative'}} onClick={() => onAddToCart(item, false)} >
                                                            <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                                            <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                                            <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
                                                                <p style={item.special_offer_price ? {textDecoration: 'line-through'} : {}}>{item.price ? item.price.toFixed(2) : item.price}</p>
                                                                <p style={{marginLeft: '8px', fontWeight: '700'}}>{item.special_offer_price ? item.special_offer_price.toFixed(2) : item.special_offer_price}</p>
                                                            </div>                                            
                                                        </div>                                        
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (section.card_size == "Medium" && section.items && section.items.length > 0) ? (
                                        <div className={`${base}__medium_menu_line`} id={section.id} key={section.id}>
                                            <div className={`${base}__item_card_heading`}>
                                                {section.text}
                                            </div>
                                            <div className={`${base}__item_cards`}>
                                                {section.items.map((item) => (
                                                    <div className="display--flex" style={{marginLeft: "1rem"}} key={item.menu_item_id}>
                                                        <div style={{position: 'relative'}} onClick={() => onAddToCart(item, false)} >
                                                            <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                                            <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                                            <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
                                                                <p style={item.special_offer_price ? {textDecoration: 'line-through'} : {}}>{item.price ? item.price.toFixed(2) : item.price}</p>
                                                                <p style={{marginLeft: '8px', fontWeight: '700'}}>{item.special_offer_price ? item.special_offer_price.toFixed(2) : item.special_offer_price}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        (section.items && section.items.length > 0) ? (
                                            <div className={`${base}__small_menu_line`} id={section.id} key={section.id}>
                                                <div className={`${base}__item_card_heading`}>
                                                    {section.text}
                                                </div>
                                                <div className={`${base}__item_cards`}>
                                                    {section.items.map((item) => (
                                                        <div className="display--flex" style={{marginLeft: "1rem"}} key={item.menu_item_id}>
                                                            <div style={{position: 'relative'}} onClick={() => onAddToCart(item, false)} >
                                                                <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                                                <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                                                <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
                                                                    <p style={item.special_offer_price ? {textDecoration: 'line-through'} : {}}>{item.price ? item.price.toFixed(2) : item.price}</p>
                                                                    <p style={{marginLeft: '8px', fontWeight: '700'}}>{item.special_offer_price ? item.special_offer_price.toFixed(2) : item.special_offer_price}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            (section.for_mix_and_match_combo && section.mix_and_match_combos && section.mix_and_match_combos.length > 0 && (
                                                <div className={`${base}__medium_menu_line`} id={section.id} key={section.id}>
                                                    <div className={`${base}__item_card_heading`}>
                                                        {section.text}
                                                    </div>
                                                    <div className={`${base}__item_cards`}>
                                                        {section.mix_and_match_combos.map((item) => (
                                                            <div className="display--flex" style={{marginLeft: "1rem"}} key={item.id}>
                                                                <div style={{position: 'relative'}} onClick={() => configureCombo(item)}>
                                                                    <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                                                    <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                                                    <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
            
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    )
                                )
                            )

                        ))}
                    </div>
                    <div className={`${base}__reset_button`} onClick={handleShowSplash}>
                        Reset
                    </div>
                </div>
            )}
        </>
    );
}